import { createI18n } from 'vue-i18n'
import SystemUtils from '@/utils/SystemUtils'
import pt from '@/langs/pt'
import en from '@/langs/en'

const i18n = createI18n({
    locale: SystemUtils.getLanguage(),
    fallbackLocale: 'en',
    messages: {
        'en': en,
        'pt-BR': pt,
        'pt': pt,
    },
})

export default i18n