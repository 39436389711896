<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="me-4 mx-auto d-lg-none" to="/">
        <CRow>
          <CCol>
            <logo/> 
          </CCol>
          <CCol class="mt-2">
            Sysvivarium
          </CCol>
        </CRow>
      </CHeaderBrand>
      <!-- <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="" id="dashboard-link">Dashboard</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink @click="$utils.redirect('settings')" id="settings-link">Configurações</CNavLink>
        </CNavItem>
      </CHeaderNav> -->
      <CHeaderNav class="ms-auto me-4">
        <CButtonGroup aria-label="Theme switch">
          <CFormCheck
            id="btn-light-theme"
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'default'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'default',
                })
            "
          >
            <template #label><CIcon icon="cil-sun" /></template>
          </CFormCheck>
          <CFormCheck
            id="btn-dark-theme"
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'dark'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'dark',
                })
            "
          >
            <template #label><CIcon icon="cil-moon" /></template>
          </CFormCheck>
        </CButtonGroup>
      </CHeaderNav>
      <CHeaderNav class="me-3">
        <QRScanner/>
      </CHeaderNav>
      <CHeaderNav class="me-3">
        <CIcon icon="cil-search" size="lg" class="text-secondary cursor-pointer"/>
      </CHeaderNav>
      <CHeaderNav class="me-3">
        <CIcon icon="cil-bell" size="lg" class="text-secondary cursor-pointer"/>
      </CHeaderNav>
      <CHeaderNav class="me-3">
        <span>|</span>
      </CHeaderNav>
      <CHeaderNav class="me-3">
        <b>{{$store.state.username}}</b>
      </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
  import AppBreadcrumb from './AppBreadcrumb'
  import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
  import logo from '@/assets/logo'
  import QRScanner from '@/components/dashboard/QRScanner'

  export default {
    name: 'AppHeader',
    components: {
      AppBreadcrumb,
      AppHeaderDropdownAccnt,
      logo,
      QRScanner
    },
  }
</script>
