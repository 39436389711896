export default [
  {
    component: 'CNavItem',
    name: 'dashboard',
    to: '/dashboard',
    icon: 'cil-chart-pie',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'animals',
    to: '/animal',
    icon: 'cil-animal',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'enclosures',
    to: '/enclosures',
    icon: 'cil-center-focus',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'people',
    to: '/people',
    icon: 'cil-people',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'alerts',
    to: '/alert',
    icon: 'cil-warning',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'consumables',
    to: '/consumable',
    icon: 'cil-bar-chart',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'requests',
    to: '/request',
    icon: 'cil-mobile-landscape',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'events',
    to: '/event',
    icon: 'cil-book',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'facility',
    to: '/facility',
    icon: 'cil-building',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    tag: 'hr'
  },
  {
    component: 'CNavItem',
    name: 'settings',
    to: '/settings',
    icon: 'cil-cog',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'report-validation',
    to: '/validate-report',
    icon: 'cil-calendar-check',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: 'support',
    to: '/support',
    icon: 'cil-chat-bubble',
    badge: {
      // color: 'info',
      // text: 'NEW',
    },
  },
]
