const loading = {
    show: ()=> {
        try {
            Array.from(document.querySelectorAll('#loading_g_temp____')).forEach(l=>{
                l.remove()
            })
        } catch (error) {
            console.log(error)
        }

        let gif = '/assets/images/loading.gif'
        let img = document.createElement('img')
        let img_container = document.createElement('div')

        img_container.setAttribute('id', 'loading_g_temp____')
        img_container.style.position = 'fixed'
        img_container.style.top = 0
        img_container.style.left = 0
        img_container.style.backgroundColor = 'white'
        img_container.style.opacity = '0.5'
        img_container.style.width = '100%'
        img_container.style.height = '100%'
        img_container.style.zIndex = '99999'
        img_container.style.opacity = '0.9'

        img.setAttribute('src', gif)
        img.style.position = 'fixed'
        img.style.top = '20%'
        img.style.left = 'calc(50% - 50px)'
        img.style.width = '40px'
        img.style.opacity = '2'
        
        img_container.appendChild(
            img
        )
        document.body.appendChild(
            img_container
        )
    },
    hide: () => {
        
        try {
            Array.from(document.querySelectorAll('#loading_g_temp____')).forEach(l=>{
                setTimeout(() => {
                    l.remove() 
                }, 500);
            })
        } catch (error) {
            console.log(error)
        }
    }
}
export default loading