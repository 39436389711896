import Tour from 'tour'

export default () => {
    const myTour = {
        canExit: true,
        nextText: 'Proceed!',
        steps: [
            {
                target: '#dashboard-link',
                content: 'Dashboard. Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            },
            {
                target: '#settings-link',
                content: 'Configurações. Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            },
        ],
    };
    setTimeout(() => {
        try {
            Tour.start(myTour)
                .then(() => {
                    console.log('Tour Finished!');
                })
                .catch((e) => {
                    console.log(e)
                    console.log('Tour Interrupted!')
                }); 
        } catch (error) {
            console.log(error)
        }
    }, 800);
}