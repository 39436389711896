import Tour from '@/plugins/Tour'
import request from '@/api/request'
import popupMsg from '@/utils/popupMsg'
import defaultPhoto from '@/utils/defaultPhoto'

const SystemUtils = {
    Tour,
    request,
    popupMsg,
    redirect(url = ''){
        window.location.href = `#/${url}`
    },
    getLanguage(){
        try {
            return navigator.language || navigator.userLanguage
        } catch (error) {
            return 'en'
        }
    },
    setUserData(token=localStorage.getItem('user-token'), context){
        let store = context.$.appContext.provides.store
        let i18n = context.$.appContext.app.__VUE_I18N__
        if (token){
            localStorage.setItem('user-token', token)
        }
        
        request(
            'account/get_personal_info/',
            'GET',
            {},
            (r)=>{
                localStorage.setItem(
                    'user-data', 
                    JSON.stringify(r.data)
                )
                store.state.username = r.data.name
                store.state.userphoto = r.data.photo == null ? defaultPhoto : r.data.photo
                if(!r.data.idiom){
                    SystemUtils.setUserIdiom()
                }else{
                    i18n.global.locale = r.data.idiom
                }
            },
            (r)=>{
                console.log(r)
            }
        )
    },
    getUserID(){
        try {
            return JSON.parse(
                localStorage.getItem(
                    'user-data'
                )
            ).id
        } catch (error) {
            console.log('user-data empty')
            return null
        }
    },
    setUserIdiom(idiom = SystemUtils.getLanguage()){
        const user_id = SystemUtils.getUserID()

        if (user_id == null){
            console.log('user.id is null')
            return
        }

        request(
            `account/update_idiom/`,
            'POST',
            {
                'idiom': idiom
            },
            (r)=>{
                console.log('idioma salvo')
            },
            (r)=>{
                console.log(r)
            }
        )
    }
}

export default SystemUtils