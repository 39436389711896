export const namespaced = true;

export const state = {
  id: undefined,
  messages: [],
};

export const mutations = {
  SEND_MESSAGE(state, message) {
    // state.messages.push(message);
    var index = state.messages.findIndex(x => x.id==message.id);
    index === -1 ? state.messages.push(message) : console.log("This item already exists");
  },
  SET_CHAT_ID(state, chat_id) {
    state.id = chat_id;
  },
};
