import axios from 'axios'
import backend from '@/api/backend'
import popupMsg from '@/utils/popupMsg'
import loading from './loading'

const request = (
    endpoint = '', 
    method = '', 
    data = {}, 
    callback = () => {}, 
    callback_error = () => {},
    is_loading = false
    ) => {
        let auth = {}
        if (localStorage.getItem('user-token') != null && localStorage.getItem('user-token') != undefined && localStorage.getItem('user-token') != ''){
            auth = {
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`
            }
        }
        if (is_loading) loading.show()
        axios({
            method: method,
            url: `${backend.baseURL}${endpoint}`,
            data: data,
            headers: {'Content-Type':'application/json', ...auth},
            // timeout: 25000
        })
        .then((response) => {
            loading.hide()
            callback(response)
        })
        .catch((e) => {
            loading.hide()
            callback_error(e)
            try {
                if (e.response.status == 401 || e.response.status == 403){
                    //REFRESH TOKEN
                    
                    popupMsg(
                        'Acesso não autorizado',
                        'd'
                    )
                    setTimeout(()=>{
                        localStorage.clear()
                        window.location.reload()
                    }, 3000)
                }else{
                    popupMsg(
                        'Desculpe, houve um problema de conexão',
                        'd'
                    )
                }
            } catch (error) {
                console.log('Desculpe, verifique sua conexão e tente novamente')
                // popupMsg(
                //     'Desculpe, verifique sua conexão e tente novamente',
                //     'd'
                // )
            }
            try {
                //timeout
                if(e.code == 'ECONNABORTED'){
                    popupMsg(
                        'Desculpe, o tempo de resposta foi maior que o esperado. Verifique sua conexão e tente novamente.',
                        'd'
                    )
                }
            } catch (error) {
                console.log(error)
            }
        })
}

export default request