import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/plugins/I18n'
import SystemUtils from '@/utils/SystemUtils'
import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import 'toastify-js/src/toastify.css'

const app = createApp(App)
app.use(i18n)
app.use(SystemUtils)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.provide('$utils', SystemUtils)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.config.globalProperties.$utils = SystemUtils

app.mount('#app')
