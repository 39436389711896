<template>
  <router-view />
  <chatbox/>
  <!-- <div id="vivar-loading-overlay">
    <CSpinner size="xl"/>
  </div> -->
</template>
<script>
  import { watch } from 'vue'
  import { useStore } from 'vuex'
  import chatbox from '@/components/chat/ChatBox'

  export default {
    components: { chatbox },
    setup() {
      const store = useStore()

      watch(store.state, () => {
        store.state.theme === 'dark'
          ? document.body.classList.add('dark-theme')
          : document.body.classList.remove('dark-theme')
      })

      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    },
  }
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
