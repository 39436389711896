<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-start" class="py-0" :caret="false">
      <CAvatar :src="$store.state.userphoto" size="md" style="background: #efefef"/>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-dark fw-semibold py-2">
        {{$t('account')}}
      </CDropdownHeader>
      <CDropdownItem>
        <CIcon icon="cil-bell" /> {{$t('updates')}}
        <CBadge color="info-gradient" class="ms-auto">{{ 12 }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> {{$t('messages')}}
        <CBadge color="success-gradient" class="ms-auto">{{
          5
        }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-task" /> {{$t('tasks')}}
        <CBadge color="danger-gradient" class="ms-auto">{{
          13
        }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-comment-square" /> {{$t('comments')}}
        <CBadge color="warning-gradient" class="ms-auto">{{
          1
        }}</CBadge>
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-dark fw-semibold py-2">
        {{$t('preferences')}}
      </CDropdownHeader>
      <CDropdownItem> <CIcon icon="cil-user" /> {{$t('profile')}} </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings"  @click="$utils.redirect('settings')"  /> {{$t('settings')}} </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem @click="logout"  class="cursor-pointer"> <CIcon icon="cil-lock-locked"/> {{$t('logout')}} </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
  import avatar from '@/assets/images/avatars/8.jpg'
  export default {
    name: 'AppHeaderDropdownAccnt',
    mounted(){
      this.$utils.setUserData(
        localStorage.getItem('user-token'),
        this
      )
    },
    data() {
      return {
        avatar: avatar,
        itemsCount: 42,
      }
    },
    methods:{
      logout(){
        localStorage.clear()
        // this.$utils.redirect('')
        setTimeout(
          ()=>{
            window.location.reload()
          },
          '300'
        )
      }
    }
  }
</script>
