import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import allowedRoutes from './allowedRoutes'
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () =>
          import('@/views/pages/Settings.vue'),
      },
      {
        path: '/enclosures',
        name: 'Enclosures',
        component: () =>
          import('@/views/enclosure/EnclosureList.vue'),
      },
      {
        path: '/enclosure',
        name: 'Enclosure',
        component: () =>
          import('@/views/enclosure/EnclosureManagement.vue'),
      },
      {
        path: '/animal',
        name: 'animals',
        component: () =>
          import('@/views/animal/AnimalList.vue'),
      },
      {
        path: '/animal_management',
        name: 'animal',
        component: () =>
          import('@/views/animal/AnimalManagement.vue'),
      },
      {
        path: '/example',
        name: 'example',
        component: () =>
          import('@/views/pages/Example.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: '/',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  //BeforeEach
  /* 
    Se !allowedRoutes.incldues(current_route)
      Se logado
        passa
      se não
        redireciona para login
  */
  if(!allowedRoutes.includes(to.name)){
    if(localStorage.getItem('user-token')){
      next()
    }else{
      next("/")
    }
  }else{
    next()
  }
  // console.log(`Navigating to: ${to.name}`)
  // next()
})

export default router
