<template>
  <div class="chatbox-icon" v-if="!show_chat" @click="toggle_chat">
    <CButton color="primary" size="lg">
      <CIcon icon="cilChatBubble"/> {{$t('support')}}
    </CButton>
  </div>
  <div v-else class="chatbox">
    <CCard>
      <CCardHeader component="h5">
        Chat
        <span class="float-end cursor-pointer" @click="toggle_chat">&times;</span>
      </CCardHeader>
      <CCardBody v-if="!chat_login">
          <div class="chatlogin">
            <CRow>
              <p>{{$t('contact-support')}}</p>
            </CRow>
            <CRow>
              <CCol>
                <CFormInput
                  id="chat-email"
                  type="email"
                  v-model="credentials.email"
                  v-on:keyup.enter="login"
                  :placeholder="$t('type-email')"
                ></CFormInput>
              </CCol>
            </CRow >
            <CRow >
              <CCol>
                <CFormInput
                  id="chat-name"
                  type="text"
                  v-model="credentials.name"
                  v-on:keyup.enter="login"
                  :placeholder="$t('type-name')"
                ></CFormInput>
              </CCol>
            </CRow >
            <CRow >
              <CCol>
                <CButton variant="outline" @click="login()" color="success">
                  {{$t('enter')}}
                </CButton>
              </CCol>
            </CRow >
            <CRow >
              <CCol>
                <ul class="errorlist">
                  <li class="errormessage" v-for="(error, i) in errors" :key="i">
                    <p>{{ error }}</p>
                  </li>
                </ul>
              </CCol>
            </CRow >
          </div>
      </CCardBody>
      <CCardBody v-if="chat_login" class="chatmessages">
        <CRow v-for="(item, index) in items" :key="index" :class="`mt-1 ${item.user == credentials.name ? 'justify-content-end' : 'justify-content-start'}`">
          <CCol md="11" :class="`msg pt-1`">
            <CRow>
              <CCol md="2">
                <img class="img-circle" :src="require(item.user != credentials.name?'@/assets/logo.png':'@/assets/images/avatars/6.jpg')" />
              </CCol>
              <CCol md="10">
                <p :msg-id="item.id">{{ item.message }}</p>
              </CCol>
            </CRow>
            <CRow >
              <CCol class="text-end">
                <small>
                  {{ item.date }}
                </small>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <div class="chatinput" v-if="chat_login">
          <CRow>
            <CCol>
              <CInputGroup>
                <CFormInput
                  id="chat-input"
                  v-on:keyup.enter="send_message"
                  v-model="chatMessage"
                  :disabled="connectionError"
                  :placeholder="buttonPlaceholder"
                />
                <CButton variant="outline" v-if="!connectionError" @click="send_message()" color="success">
                  <CIcon 
                    icon="cilPaperPlane" 
                  />
                </CButton>
                <CButton variant="outline" v-else disabled color="warning">
                  <CIcon 
                    icon="cilWifiSignalOff" 
                  />
                </CButton>
              </CInputGroup>
            </CCol>
          </CRow >
        </div>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import backend from '@/api/backend'
export default {
  setup () {
    const data = reactive({
      credentials: { email: '', name: '' },
      localLogin: false,
      chatsocket: undefined,
      chatMessage: "",
      connectionError: false,
      errors: [],
      show_chat: false,
    })
    return {...toRefs(data)}
  },

  computed: {
    buttonPlaceholder() {
      return this.connectionError
        ? "Tentando reconectar, por favor aguarde."
        : "Digite sua mensagem";
    },

    global_login() {
      return this.$store.state.logged;
    },

    chat_login() {
      return this.global_login ? true : this.localLogin;
    },

    items() {
      return this.$store.state.chat.messages;
    },

    stored_chat_id() {
      return this.$store.state.chat.id;
    },
  },

  methods: {
    toggle_chat(){
      this.show_chat = !this.show_chat
    },
    gen_query_string() {
      if (this.global_login == true) {
        return "";
      }
      if (this.chat_login == true) {
        return (
          "?email=" + this.credentials.email + "&name=" + this.credentials.name
        );
      }
    },

    connect_websocket: function(initialMessage) {
      var self = this;

      if (self.stored_chat_id) {
        self.chatsocket = new WebSocket(
          `${backend.wsURL}ws/chat/` +
            self.stored_chat_id +
            "/" +
            self.gen_query_string()
        );
      } else {
        self.chatsocket = new WebSocket(
          `${backend.wsURL}ws/chat/` + self.gen_query_string()
        );
      }

      self.chatsocket.onopen = function(event) {
        if (initialMessage) {
          self.chatsocket.send(initialMessage);
        }
        self.connectionError = false
      };

      self.chatsocket.onerror = function(event) {
        self.chatsocket.close();
      };

      self.chatsocket.onmessage = function(event) {
        let data = JSON.parse(event.data);
        self.$store.commit("chat/SET_CHAT_ID", data.chat_id);

        self.$store.commit("chat/SEND_MESSAGE", {
          id: data.message_id,
          user: data.user,
          message: data.message,
          date: data.message_date,
        });    
      };

      self.chatsocket.onclose = function() {
        console.log('fechou websocket')
        self.chatMessage = ''
        self.connectionError = true
        setTimeout(function() {
          self.connect_websocket()
        }, 5000);
      };
    },

    send_message() {
      if (
        this.chatsocket == undefined ||
        this.chatsocket.readyState !== this.chatsocket.OPEN
      ) {
        this.connect_websocket(
          JSON.stringify({
            message: this.chatMessage,
          })
        );
      } else {
        this.chatsocket.send(
          JSON.stringify({
            message: this.chatMessage,
          })
        );
        console.log('oi')
        this.chatMessage = "";
      }
    },

    login() {

      this.errors = []

      if (!this.credentials.email) {
        this.errors.push("Preencha o email");
        
        try {
          document.querySelectorAll(`[id=chat-email]`)[0].focus()
        } catch (error) {
          console.log(error)
        }

      } else if (!this.validEmail(this.credentials.email)) {
        this.errors.push("Utilize um e-mail válido.");
        
        try {
          document.querySelectorAll(`[id=chat-email]`)[0].focus()
        } catch (error) {
          console.log(error)
        }

      }

      if (!this.credentials.name) {
        this.errors.push("Preencha o nome");
        
        try {
          document.querySelectorAll(`[id=chat-name]`)[0].focus()
        } catch (error) {
          console.log(error)
        }

      }

      if (!this.errors.length) {
        this.credentials.email;
        this.localLogin = true;
        this.$store.commit("chat/SEND_MESSAGE", {
          id: 0,
          user: 'sysvivarium',
          message: 'Olá, ' + this.credentials.name + ' digite abaixo o motivo do seu contato.',
          date: '',
        });
      }

      for (var error in this.errors) {
        console.log(this.errors[error]);
      }
    },

    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },

  updated(){
    try {
      setTimeout(() => {
        document.querySelectorAll(`chatmessages`)[0].scrollTop = document.querySelectorAll(`chatmessages`)[0].scrollHeight
      }, 500);
    } catch (error) {
      console.log(error)
    } 
  }
};
</script>

<style lang="scss">

  .chatbox {
    position: fixed;
    bottom: 55px;
    right: 10px;
    .card-body{
      width: 320px;
      height: 500px;
      overflow-y: auto;
    }
  }

  .chatbox-icon {
    position: fixed;
    bottom: 55px;
    right: 10px;
    z-index: 9999;
  }

  .chatmessages {
    .msg{
      border-radius: 5px;
      border: solid 0.1px #afafaf50;
    }
  }

  .errorlist {
    list-style-type: none;
  }

  .errormessage {
    p {
      color: #ff000050;
    }
  }

  .chatlogin {
    padding: 10px;
    .row {
      padding-bottom: 10px;
    }
  }

  .img-circle {
    width: 30px;
    height: 30px;
  }

  .text-container {
    position: relative;
    .img-circle {
      position: absolute;
      top: 2px;
      left: 10px;
    }

    p {
      margin-left: 40px;
    }
  }
</style>
